footer {
	position: relative;
	width: 100%;
	z-index: 1;
	background-image: url(../images/bg.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-top: 2px solid #000;
}

.ftr-wrap {
	position: relative;
	z-index: 1;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 45px 0 10px;
	@include respond-to('large') {
		padding: 35px 0 10px; 
	}
	@include respond-to('small') {
		padding: 25px 0 10px;
	}
	& .section-limit {}
	&__left {
		width: 79%;
		float: $left;
		@include respond-to('1100') {
			float: none;
			width: 100%;
			padding-bottom: 10px;
		}
		@include respond-to('small') {
			padding-bottom: 10px;
		}
	}
	&__right {
		width: 21%;
		float: $right;
		@include respond-to('1100') {
			float: none;
			width: 100%;
			max-width: 300px;
			margin: 0 auto 15px;
			text-align: $right;
		}
		@include respond-to('medium') {
			margin-bottom: 0;
		}
	}
}

.link-col {
	float: $right;
	width: 25%;
	padding: 0 5px;
	@include respond-to('small') {
		width: 50%;
	}
	@include respond-to('mobile-l') {
		width: 100%;
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #cecece;
	margin-top: 40px;
	margin-bottom: 10px;
	@include respond-to('large') {
		margin-top: 20px;
		margin-bottom: 10px;
	}
	@include respond-to('extra-small') {
		margin-top: 10px;
	}
}

.ftr-logo {
	position: relative;
	text-align: center;
	margin-top: 30px;
	@include respond-to('1100') {
		margin-top: 0px;
		margin-bottom: 15px;
	}
	& img {
		// max-width: 270px;
		@include respond-to('medium') {
			// max-width: 150px;
		}
	}
}

.site-footer__credit-holder {
	padding: 0 0 10px;
}
