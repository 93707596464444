.side-form-container {
	position: fixed;
	top: 0;
	#{$left}: -245px;
	height: 100vh;
	max-height: 1000px;
	width: 245px;
	z-index: 101;
	background-color: transparent;
	transition: left 0.5s ease;
	&_open {
		#{$left}: 0;
	}
}

.side-form {
	width: 100%;
	height: 100%;
	box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.1);
	&__button {
		position: absolute;
		#{$right}: -80px;
		top: 43%;
		z-index: 1;
		@include respond-to('small') {
			#{$right}: -60px;
		}
	}
	&__form-body {
		position: absolute;
		z-index: 1;
		#{$left}: 0;
		top: 0;
		max-width: 245px;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
	}
}

.side-btn {
	width: 90px;
	height: 80px;
	background-color: $accent;
	position: absolute;
	top: 50%;
	// #{$right}: 105px;
	// transform: translateY(-50%) rotate(-45deg);
	box-shadow: 0 0 20px rgba(0, 0, 0, .08);
	cursor: pointer;
	pointer-events: all;
	@include respond-to('small') {
		width: 60px;
		height: 60px;
		// #{$right}: 115px;
	}
	&:hover {
		cursor: pointer;
	}
	&_close {
		// background-color: #fff;
	}
	&__content {
		// transform: rotate(45deg);
		width: 100%;
		height: 100%;
		// text-align: left;
		// margin-top: 40px;
		// margin-#{$left}: 50%;
		// padding-#{$right}: 25px;
		transition: .5s;
		opacity: 1;
		pointer-events: none;
		display: flex;
		justify-content: center;
		align-items: center;
		@include respond-to('small') {
			// margin-top: 22px;
			// padding-#{$right}: 17px;
		}
		span {
			display: block;
			color: #fff;
			@include respond-to('small') {
				& img {
					max-width: 40px;
				}
			}
			&.btn-text { 
				max-width: 50px;
				@include respond-to('larger') {
					// max-width: 40px;
				}
			}
		}
		//span

		&_close {
			position: absolute;
			top: 0;
			text-align: $right;
			// padding-#{$right}: 15px;
			// margin-top: 25px;
			// background: #fff;
			@include respond-to('small') {
				// padding-#{$right}: 9px;
				// margin-top: 13px;
			}
			& span {
				color: #fff;
			}
			& i {
				margin-#{$right}: 5px;
				margin-#{$left}: 5px;
				color: #fff;
				font-size: 35px;
				@include respond-to('small') {
					font-size: 25px;
				}
			}
		}
		//_close

		&_hide {
			opacity: 0;
		}
	}
	//_btn-content
	&__image {
		width: 40%;
		margin-#{$left}: 60%;
		margin-bottom: 10px;

		@include respond-to('larger') {
			// margin-top: 15px;
		}
		//text-align: center;
	}
}

.form-body {
	// box-shadow: 5px 0 20px 0 rgba(0, 0, 0, .15);
	&__head {
		padding: 10px 10px;
		border-bottom: 1px solid #dbdbdb;
		background-color: #000;
		min-height: 145px;
		text-align: center;
		@include respond-to('large') {
			min-height: 100px;
		}
	}
	&__head-logo {
		display: inline-block;
		& img {
			margin-top: 20px;
			margin-bottom: 20px;
			max-width: 215px;
			@include respond-to('medium') {
				max-width: 170px;
				margin-top: 0px;
				margin-bottom: 0px;
			}
		}
	}
	&__main {
		height: calc(100% - 175px);
		overflow-y: auto;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		&::-webkit-scrollbar {
			// Safari and Chrome
			display: none;
		}
		-ms-overflow-style: none; // IE 10+
		overflow: -moz-scrollbars-none; // Firefox
		// @include respond-to('larger') {
		// 	height: calc(100% - 92px);
		// }
		// @include respond-to('medium') {
		// 	height: calc(100% - 62px);
		// }
		@include respond-to('medium') {
			height: calc(100% - 115px);
		}
	}
	&__contact {
		background-color: #f8f8f8;
		border-bottom: 1px solid #d6d6d6;
	}
	&__form {
		padding: 35px 15px;
		// @include respond-to('extra-small') {
		// 	padding: 15px;
		// }
	}
}

.form-contact {
	display: block;
	max-width: 210px;
	width: 100%;
	margin: 0 auto;
	padding: 25px 0;
	text-align: center;
	&__title {
		margin-top: 10px;
		color: #000;
		font-size: 18px;
		font-weight: 400;
		line-height: 1;
	}
	&__phone {
		color: #000;
		font-size: 25px;
		font-weight: 700;
		line-height: 1;
	}
	&__icon {
		& img {
			// @include respond-to('extra-small') {
			// 	max-width: 45px;
			// }
		}
	}
}

.form-form {
	max-width: 210px;
	width: 100%;
	&__title {
		text-align: center;
		& p {
			margin: 0;
			color: #000;
			font-weight: 400;
			line-height: 1;
			font-size: 20px;
		}
		& p:first-child {
			font-size: 25px;
			color: #000;
			font-weight: 700;
		}
	}
	&__fields {
		padding-top: 20px;
	}
	& .form-group {
		margin-bottom: 0;
	}
	& .newslatter-input {
		height: 50px;
		width: 100%;
		padding: 11px 10px 10px;
		background-color: #fff;
		color: #626262;
		font-weight: 400;
		font-size: 18px;
		border: none;
		border-bottom: 1px solid #a1a1a1;
		border-radius: 0;
		box-shadow: none;
		@include respond-to('extra-small') {
			padding: 14px 10px 13px;
		}
		&::placeholder {
			font-size: 18px;
			color: #626262;
		}
	}
	& .newslatter-area {
		height: 100px;
		width: 100%;
		resize: none;
		color: #626262;
		font-weight: 400;
		font-size: 18px;
		border: none;
		border-bottom: 1px solid #a1a1a1;
		border-radius: 0;
		box-shadow: none;
		&::placeholder {
			font-size: 18px;
			color: #626262;
		}
	}
	& .newslatter-btn {
		width: 100%;
		margin: 0;
		margin-top: 30px;
		height: 50px;
		font-size: 18px;
		font-weight: 700;
		background-color: $accent;
		color: #fff;
		border: none;
		&:active {
			@include btn-effect-active;
		}
	}
}

.help-block {
	font-size: 16px;
	color: #000 !important;
}
