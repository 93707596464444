.wrap-content {
	padding: 20px 0;
}

.all-content {
	// background-color: #000;
}

.content-info {
	text-align: justify;
	& h1 {
		font-size: 38px;
		font-weight: 500;
		line-height: 1;
		border: none;
		color: #FFFFFF;
		margin-top: 40px;
		margin-bottom: 20px;
		@include respond-to('small') {
			font-size: 25px;
			margin: 20px 0;
		}
	}
	& h2 {
		margin-top: 35px;
		margin-bottom: 0;
		font-size: 20px;
		color: #FFFFFF;
		font-weight: 700;
	}
	& p {
		font-size: 17px;
		color: #FFFFFF;
		font-weight: 400;
	}
}

.center-blocks {
	& ul {
		margin: 0;
		padding: 0;
		list-style: none;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 70px;
		display: flex;
		flex-wrap: nowrap;
		text-align: center;
		@include respond-to('small') {
			display: flex;
			flex-wrap: wrap;
		}
		& li {
			max-width: 180px;
			text-align: center;
			line-height: 60px;
			font-size: 18px;
			font-weight: 500;
			padding-bottom: 35px;
			@include respond-to('medium') {
				margin-left: auto;
				margin-right: auto;
				max-width: 130px;
			}
			@include respond-to('mobile-m') {
				max-width: 120px;
			}
			& img {}
			& p {
				color: #FFFFFF;
				font-size: 17px;
				font-weight: 500;
				line-height: 20px;
				padding-top: 25px;
			}
		}
	}
}

.wrap-all-content-not-banner,
.padding-top-catalog-item {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.hr-content {
	border-top: 1px solid #d9d9d9;
	margin: 0 0 20px;
}

.content-text {
	h2,
	h3 {
		font-size: 30px;
		font-weight: 700;
		line-height: 1.2;
		color: #fff;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 26px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 17px;
		font-weight: 400;
		color: #fff;
		line-height: 1.2;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}