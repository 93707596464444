.scroll-btn-wrap {
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translate(-50%, 0%);
	z-index: 2;
	display: inline-block;
	width: 30px;
	height: 50px;
	color: #fff;
	transition: opacity 0.3s;
	@include respond-to('small') {
		display: none;
	}
	&:hover {
		opacity: 0.5;
		cursor: pointer;
	}
}

.scroll-btn {
	position: relative;
	display: block;
	height: 100%;
	width: 100%;
	border: 2px solid #fff;
	border-radius: 50px;
	&::before {
		position: absolute;
		top: 10px;
		left: 50%;
		content: '';
		width: 6px;
		height: 6px;
		margin-left: -3px;
		background-color: #fff;
		border-radius: 100%;
		animation: scroll-anim 2s infinite;
		box-sizing: border-box;
	}
	&:hover {
		&::before {
			animation-duration: 1s;
		}
	}
}

@keyframes scroll-anim {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	80% {
		transform: translate(0, 20px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
