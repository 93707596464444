.breadcrumb {
	background-color: transparent;
	margin-bottom: 0;
	padding: 14px 0;
	@include respond-to('small') {
		display: none;
	}
	& li {
		font-size: 16px;
		&::before {
			color: #fff;
		}
		& a {
			text-decoration: none;
			color: #777777;
			font-size: 16px;
			font-weight: 300;
		}
	}
	& .active {
		color: #fff;
	}
}
