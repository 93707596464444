.ftr-links-koteret {
	position: relative;
	font-size: 20px;
	font-weight: 500;
	color: #fff;
	margin-bottom: 15px;
	padding-bottom: 15px;
	&::after {
		content: '';
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
		bottom: 0;
		width: 30px;
		height: 2px;
		background: $accent;
	}
	@include respond-to('medium') {
		font-size: 18px;
		margin-bottom: 10px;
		padding-bottom: 10px;
	}
	@include respond-to('extra-small') {
		margin-bottom: 10px;
		padding-bottom: 10px;
	}
}

.ftr-wrap-links {
	max-width: 200px;
	margin: 0 auto;
	@include respond-to('small') {
		margin-bottom: 10px; 
		margin-left: auto;
		margin-right: auto;
	}
	&:hover {}
	& > ul {
		padding: 0;
		margin: 0;
		list-style: none;
		& > li {
			margin-bottom: 10px;
			font-size: 16px;
			font-weight: 400;
			color: #fff;
			@include respond-to('medium') {
				margin-bottom: 3px;
				font-size: 16px;
			}
			& > a {
				font-size: 16px;
				font-weight: 400;
				color: #fff;
				@include respond-to('medium') {
					font-size: 16px;
				}
			}
		}
	}
}