.media-content{
  padding:0px 20px;
}

.media-years{
  width:100%;
  height:75px; 
  ul{
    width:100%;
    height:100%;
    margin:0;
    padding:20px 0px;
    list-style-type: none;

    li{
      list-style-type: none;
      display:inline-block;

      a{
        font-size:20px;
        font-weight: bold;
        color:#777777;
        padding:2px 0px;
        border-bottom:2px solid #777777;
        margin-left:20px;
        text-decoration: none !important;
        &:hover,&:focus,&.selected{
          color:#777777;
          border-bottom:2px solid #777777;
        }
      }
    }
  }
}

.media-p-right{
  width:100%;
  max-width:460px;
  margin:0;
  padding:0;
  list-style: none;
  display:none;
  @include respond-to('medium') {
    max-width:100%;
  }
  &.show{
    display:none
  }
  li{
    width: 100%;
    min-height: 80px;
    border-top: 1px solid #ccc;
    padding: 5px 0;

    
  }
}

.media-item{
  width:100%;
  height:100%;
  display:block;
  cursor: pointer;
  padding: 15px;
  text-decoration: none !important;
  .date{
    font-size:20px;
    color:#777777;
    font-weight: bold;
  }
  .sep{
    font-size:20px;
    color:#777777;
  }
  .title{
    font-size:20px;
    color:#777777;
    font-weight: bold;
    margin:0;
  }
  .title2{
    display:block;
    color:#777777;
    font-size:18px;
    display:inline-block;
    margin:0;
  }

  .desc{
    color:#000;
    margin:0;
  }


  &:hover{
    .title{
      text-decoration: underline;
    }
  }
}

.media-image-holder{
  display:inline-block;
  margin:auto auto;
}

.media-image{
  -webkit-box-shadow: 0px 0px 43px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 43px 8px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 43px 8px rgba(0,0,0,0.1);
  position: relative;
  display:inline-block;
  margin-bottom:27px;

  &:after{
    width:184px;
    height:184px;
    position: absolute;
    top:0;
    left:0;
    content: " ";
    z-index:100;
  }
}

.media-link-title{
  font-size:16px;
  color:#777777;
  border-bottom:2px solid #777777;
}
