.wrapper-banners {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	@include respond-to('1550') {
		height: 700px;
	}
	@include respond-to('huge') {
		height: 650px;
	}
	@include respond-to('larger') {
		height: 550px;
	}
	@include respond-to('large') {
		height: 450px;
	}
	@include respond-to('1100') {
		height: 400px;
	}
	@include respond-to('medium') {
		height: 350px;
	}
	@include respond-to('extra-small') {
		height: 300px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
    height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 100vh;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('1550') {
		min-height: 700px;
	}
	@include respond-to('huge') {
		min-height: 650px;
	}
	@include respond-to('larger') {
		min-height: 550px;
	}
	@include respond-to('large') {
		min-height: 450px;
	}
	@include respond-to('1100') {
		min-height: 400px;
	}
	@include respond-to('medium') {
		min-height: 350px;
	}
	@include respond-to('extra-small') {
		min-height: 300px;
	}
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.3);
		z-index: 1;
	}
}

.row {
	position: relative;
}