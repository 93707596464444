.lpage-wrapper-content{
  width: calc(100% - 470px);

  @include respond-to('huge'){
    width: calc(100% - 350px);
  }
  @include respond-to('medium'){
    width: 100%;
    text-align: center;
    position: relative !important;
  }
}

.lpage-section-limit{
  // max-width: 980px;
  width: 100%;
  margin: 0 auto;
	padding: 0 15px;
}

.lpage-wrapper{
  position: relative;
}

.lpage-banner{
  width: 100%;
  // height: 950px;
  height: auto;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.3);

  width: calc(100% - 470px);

  @include respond-to('huge'){
    width: calc(100% - 350px);
  }
  @include respond-to('medium'){
    width: 100%;
  }

  &__text{
    position: static;
    padding: 30px;
    background-color: rgba(255,255,255,0.8);
    font-size: 90px;
    color: #000;
    line-height: 1;
    text-align: center;
    float: $left;
    margin-#{$left}: 30px;
    @include respond-to('huge'){
      & *{
        font-size: 80px !important;
      }
    }
    @include respond-to('large'){
      & *{
        font-size: 62px !important;
      }
    }

    @include respond-to('medium'){
      padding: 15px;
      float: none;
      display: inline-block;
      & *{
        font-size: 28px !important;
      }
    }
  }
}

.lpage-logo{
  position: static;
  float: $left;
  margin-#{$left}: 180px;
  margin-bottom: 240px;
  @include respond-to('huge'){
    margin-#{$left}: 40px;
    margin-bottom: 160px;
  }
  @include respond-to('medium'){
    width: 100%;
    text-align: center;
    margin: 0 0 20px;
    float: none;
  }
  & img{
    @include respond-to('medium'){
      max-width: 250px;
      margin: 0 auto;
      // margin-left: 10px;
    }
  }
}

.lpage-content{
  padding: 50px;
  
  @include respond-to('small'){
    padding: 30px 0;
  }
  & h1{
    font-size: 40px;
    font-weight: bold;
    color: #000;
    line-height: 1;
    margin: 0 auto 20px;
    text-align: center;
    @include respond-to('larger'){
      font-size: 35px;
    }
    @include respond-to('small'){
      font-size: 24px;
    }
  }
  & h2{
    font-size: 33px;
    color: #000;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 20px;
    @include respond-to('larger'){
      font-size: 25px;
    }
    @include respond-to('small'){
      font-size: 19px;
    }
  }
  & p{
    color: #000;
    font-size: 20px;
    @include respond-to('small'){
      font-size: 16px;
    }
  }
}

.lpage-form-box{
  position: fixed;
  top: 0;
  #{$left}: 0;
  max-width: 470px;
  height: 100%;
  background-color: #171717;
  box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.5);
  padding: 50px 40px 30px;
  text-align: center;
  @include respond-to('huge'){
    max-width: 350px;
    padding: 50px 15px 30px;
  }
  
  @include respond-to('medium'){
    padding: 15px;
    position: static;
    margin: 0 auto;
  }
  @include respond-to('small'){
    max-width: unset;
  }
  &__title{
    font-size: 38px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    @include respond-to('huge'){
      font-size: 28px;
    }
  }
  &__sub-title{
    font-size: 26px;
    line-height: 1;
    color: #fff;
    margin-bottom: 45px;
    @include respond-to('huge'){
      font-size: 22px;
    }
  }
  & .custom-form {
    padding: 0 40px;
    @include respond-to('huge'){
      padding: 0 15px;
    }
    & .customCheckboxText{
      color: #fff;
      font-size: 20px;
      margin-bottom: 20px;
    }
    & .form-group{
      margin-bottom: 15px;
    }
    & .form-control{
      height: 50px;
      border-radius: 0;
      &::placeholder{
        color: #3d3d3d;
      }
    }
    & .btn-form{
      height: 50px;
      border-radius: 0;
      margin-bottom: 30px !important;
      background-color: #ba9f4a;
      background-image: linear-gradient(90deg,#ba9f4a 0,#f7f4bb 50%,#ba9f4a);
      font-weight: bold;
      margin-top: 0;
      color: #000;
      box-shadow: 0px 5px 30px 0px rgba(0,0,0,0.3);
    }
  }
}

.lpage-title-after-form{
  color: #fff;
  font-size: 29px;
  line-height: 1;
  margin: 0;
}

.lpage-phone-after-form{
  color: #fff;
  font-size: 50px;
  line-height: 1;
  font-weight: bold;
  &:hover,&:focus{
    color: #fff;
  }
}

.lpage-section-right{
  padding: 60px 0 0;
  @include respond-to('1550'){
    padding-top: 20px;
  }
  @include respond-to('medium'){
    padding-top: 0px;
  }
  & .container-fluid{
    padding: 0;
  }
}


.lpage-form-box-inner-wrap{
  height: 100%;
  overflow: hidden;
}

.lpage-form-box-inner-wrap-scroll{
  overflow-y: scroll;
  height: 100%;
  margin-left: -20px;
}