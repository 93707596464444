.project-content {}

.project-centertext {
	color: #FFFFFF;
	text-align: justify;
	& h1 {
		font-size: 38px;
		font-weight: 500;
		@include respond-to('small') {
			font-size: 25px;
			margin: 20px 0;
		}
	}
	& p {
		font-size: 15px;
		font-weight: 500;
		padding-bottom: 25px;
		max-width: 1100px;
		margin: 0;
	}
}

.project-photo {
	& .col-md-6 {
		padding: 1px;
	}
}

.menu-project {
	display: block;
	&__image {
		overflow: hidden;
		position: relative;
		height: 500px;
		@include respond-to('huge') {
			height: 400px;
		}
		@include respond-to('larger') {
			height: 350px;
		}
		@include respond-to('large') {
			height: 300px;
		}
		@include respond-to('extra-small') {
			height: 250px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: transform 200ms;
		}

	}
	&:hover {
		& .button {
			opacity: 1;
			visibility: visible;
		}
	}
	&::before {
		content: " ";
		height: 100%;
		left: 0;
		right: 0;
		z-index: 0;
	}
}

.button {
	background-color: rgba($color: #0F0000, $alpha: 0.8);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: none;
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 18px;
	font-weight: 500;
	transition: all 0.4s;
	opacity: 0;
	visibility: hidden;
	@include link_no-hover(#fff);
	@include respond-to('small') {
		opacity: 1;
		visibility: visible;
		padding: 10px 20px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 14px;
		font-weight: 400;
		background-color: rgba($color: #0F0000, $alpha: 1);
	}
	&:hover {
		background-color: rgba($color: #0F0000, $alpha: 1);
	}
}