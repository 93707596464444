.main-header {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;



	
    position: fixed;
    box-shadow: inset 0 128px 56px -56px #00000082;
	background: #000000ab;
    padding-bottom: 25px;
}

// header:after {
// 	content: '';
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	background: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0) 100%);
// 	pointer-events: none;
// 	z-index: -1;
// 	opacity: 0.5;
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// }

.header-desktop {
	@include respond-to('medium') {
		display: none;
	}
}

.header-desktop__logo {
	padding-top: 13px;
	float: right;
}

.navi {
	// float: left;
	float: $right;
	padding-top: 55px;
	margin-#{$right}: 70px;
	& > ul {
		display: inline-block;
		list-style: none;
		margin: side-values(35px 0 0 0);
		padding: 0;
		@include respond-to('large') {
			margin: 0;
		}
		& > li {
			float: right;
			margin-#{$left}: 50px;
			position: relative;
			@include respond-to('1100') {
				margin-#{$left}: 30px;
			}
			&:last-child {
				margin-#{$left}: 0px;
			}
			& > a {
				color: #ffffff;
				font-size: 18px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: normal;
				line-height: normal;
				display: block;
				text-decoration: none;
				&:hover {
					border-bottom: 2px solid #ffffff;
					color: #ffffff;
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					border-bottom: 2px solid #ffffff;
					color: #ffffff;
					text-decoration: none;
				}
				& i {
					color: #00b9cf;
				}
			}
		}
	}
}

.no-decor {
	@include link_no-hover(#fff);
	&:hover {
		border: none !important;
	}
	&_phone {
		margin-top: -5px;
		padding: 5px;
		border: 1px solid #fff;
		&:hover {
			border: 1px solid #fff !important;
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	background-color: black;

	@include respond-to('medium') {
		display: block;
	}

	&__logo {
		margin-#{$left}: 15px;
		margin-top: 10px;
		float: $left;

		@include respond-to('large') {
			max-width: 80px;
			max-height: 60px;
			z-index: 5;
			position: relative;
		}

		& > img {
			width: 200px;

		}
	}

	&__tel {
		display: inline-block;
		margin-top: 17px;

		i {
			color: #fff;
			font-size: 32px;
		}
	}

}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
	background-color: #FFF;
}


.main-header.scrolling {
	.header-desktop {
		height: 80px;
	}

	.main-navi {
		& > ul {
			margin-top: 25px;
		}
	}

	.wrap-lang {
		margin-top: 25px;
	}

	.logo {
		top: 15px;
	}
}