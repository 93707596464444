.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
	background-image: url(../images/bg.jpg);
}

.homepage__buttons {
	padding: 20px 0;
}

.hp-boxes {
	&.row {
		margin-left: -1px;
		margin-right: -1px;
	}
	& .col-md-6 {
		padding: 1px;
	}
}

.row-blocks {
	margin-right: 75px;
	margin-top: 70px;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	max-width: 570px;
	display: flex;
	flex-wrap: wrap;
	@include respond-to('larger') {
		padding: 0 15px;
		width: 100%;
		left: 0;
		margin: 0 auto;
		padding-top: 65px;
	}

	@include respond-to('extra-small') {
		position: static;
		transform: translate(0);
		top: 0;
		width: 100%;
		margin: 0 auto;
		background: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0) 100%);
		pointer-events: none;
		padding-top: 100px;

	}
	// @include respond-to('larger') {
	// 	position: static;
	// 	transform: translate(0);
	// 	top: 0;
	// 	width: 100%;
	// 	margin: 0 auto;
	// 	pointer-events: none;
	// 	padding-top: 65px;
	// }

	&__info {
		max-width: 205px;
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		padding-bottom: 18px;
		@include respond-to('large') {
			max-width: 160px;
		}
		@include respond-to('small') {
			width: 33.33%;
		}
		@include respond-to('mobile-m') {
			width: 50%;
		}
	}
	&__txt {
		margin: 0;
		font-size: 15px;
		font-weight: 500;
		color: #ffffff;
		padding: 0 5px;
		text-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.5);
	}
	&__image {
		height: 60px;
		line-height: 60px;
	}
}

.row-wr {
	position: relative;
	height: 484px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include respond-to('large') {
		min-height: 380px;
		height: unset;
	}
	@include respond-to('small') {
		min-height: 300px;
	}
	&:hover {
		& .button {
			opacity: 1;
			visibility: visible;
		}
	}
	&_bg2 {
		@include respond-to('huge') {
			background-position: bottom right;
		}
	}
}

.button {
	@include respond-to('medium') {
		opacity: 1 !important;
		visibility: visible !important;
	}
}

.rowback-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	@include respond-to('large') {
		left: 20px;
		padding-right: 15px;
		transform: translateY(-50%);
	}
	@include respond-to('small') {
		padding: 15px;
	}

	&__title {
		font-size: 26px;
		font-weight: 500;
		color: #ffffff;
		padding-bottom: 5px;
		h1{
			font-size: 21px;
			font-weight: 500;
			padding-bottom: 5px;
			margin: 0;
		}
	}

	&__info {
		font-size: 15px;
		color: #ffffff;
		font-weight: 500;
		text-align: justify;
		max-width: 375px;
		margin: 0;
		@include respond-to('large') {
			max-width: 100%;
		}
		@include respond-to('small') {
			position: static;
			transform: translate(0);
			width: 100%;
			margin: 0;
		}
	}
	& a {
		font-size: 15px;
		color: #ffffff;
		font-weight: 500;
		@include link_no-hover(#fff);
	}
}

.client-title {
	color: #292929;
	padding-top: 50px;
	padding-bottom: 50px;
	padding-right: 40px;
	font-size: 26px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: normal;
	line-height: 20px;
	@include respond-to('larger') {
		text-align: center;
		padding-right: 0px;
	}
}
.client-icon {
	margin-bottom: 25px;
	display: flex;
	flex-wrap: wrap;
	/* align-items: center; */
	justify-content: center;
	align-items: baseline;
	&__content-logo {
		padding: 10px;
		height: 100px;
		& img {
			max-height: 100%;
		}
	}
}

.background-client {
	border-top: 2px solid #fff;
	border-bottom: 2px solid #fff;
	position: relative;
	background-position: center;
	background-size: cover;
}

.clients {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.hp-rec {
	padding: 40px 0;
	&__items {}
	&__item {}
	& .slick-track {
		// display: flex;
		// justify-content: center;
		// align-items: center;
	}
}

.rec-item {
	max-width: 815px;
	width: 100%;
	margin: 0 auto;
	color: #fff;
	font-size: 15px;
	font-weight: 400;
	text-align: center;
	// min-height: 400px;
	&__quot {
		& img {
			display: inline-block;
		}
	}
	&__text {}
	&__title {
		font-size: 22px;
		text-align: center;
		margin-top: 10px;
	}
}

*{
	opacity: 1;
}