// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.section-full {
  width: 100%;
}

.section-limit {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 $padding-base;
}

.section-spacer {
  width: 100%;
  height: $section-spacer-height;
}

.row-spacer {
  width: 100%;
  float: right;
  height: $row-spacer-height;
}

.helper-blue {
  width: 100%;
  min-height: 250px;
  background-color: blue;
  opacity: 0.5;
}

.helper-red {
  width: 100%;
  min-height: 250px;
  background-color: red;
  opacity: 0.5;
}

.helper-green {
  width: 100%;
  min-height: 250px;
  background-color: green;
  opacity: 0.5;
}

.no-row-padding {
  &>div[class^="col-"] {
    padding: 0;
  }
}

img {
  max-width: 100%;
}

.helper-position-relative {
  position: relative;
}

.row {
  &_no-gutter {
    margin-left: 0;
    margin-right: 0;
    &>div[class^="col-"] {
      padding-left: 0;
      padding-right: 0;
    }
  } //_no-gutter
  &_100 {
    margin-left: -50px;
    margin-right: -50px;
    &>div[class^="col-"] {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  &_50 {
    margin-left: -25px;
    margin-right: -25px;
    &>div[class^="col-"] {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  &_10 {
    margin-left: -5px;
    margin-right: -5px;
    &>div[class^="col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  &_same-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    &>div[class^="col-"] {
      display: flex;
      flex-direction: column;
    }
  }
} //row
.box {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  &_no-padding {
    padding: 0;
  } //_no-padding
  &_bg {
    &_white {
      background-color: #fff;
    }
    _white &_black {
      background-color: #000;
    } //_black
  } //_bg
} //box